body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}

* {
  box-sizing: border-box;
}

p:last-child {
  margin-bottom: 0px;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

ul {
  margin: 0;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
}