@import '../../../styles/mixins/breakpoint.scss';
@import '../../../styles/mixins/color.scss';
@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/typography.scss';

.waiting-list-banner {
    background-color: $background;
    padding: 210px 0;
    position: relative;
    @include breakpoint("max-md") {
        padding: 120px 0;
    }
    @include breakpoint("max-md") {
        padding: 80px 0;
    }
    .grid {
        display: grid;
        grid-template-columns: minmax(0 , 1fr) 344px;
        gap: 0 60px;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 40px 0;
        }
        .grid-items:nth-child(2) {
            position: relative;
            .ball-image-right {
                background-image: url("../../../Assets/Images/ball.png");
                background-repeat: no-repeat;
                background-size: cover;
                width: 33px;
                height: 32px;
                position: absolute;
                top: 0;
                right: 0;
            }
            .mail-last-child {
                background-image: url("../../../Assets/Images/vector5.png");
                background-repeat: no-repeat;
                background-size: cover;
                width: 58px;
                height: 47px;
                position: absolute;
                left: 0;
                bottom: 20%;
            }
        }
        .grid-items {
            .mail-image {
                @include breakpoint("max-md") {
                    display: flex;
                    justify-content: center;

                }
            }
            h1 {
                @include heading-large;
                color: $white;
                margin: 0 0 30px 0;
                @include breakpoint("max-sm") {
                    font-size: 2rem !important;
                    font-weight: 600;
                    line-height: 1.3 !important;
                }
            }
            p {
                @include text;
                color: $gray-2;
                margin: 0 0 48px 0;
                line-height: 25px;
            }
            .form-group-box {
                max-width: 476px;
                @include breakpoint("max-sm") {
                    max-width: 100%;
                }
                .join-button {
                    button {
                        background: linear-gradient(269.99deg, #EC7C3E 15.27%, #E49061 117.7%);
                        box-shadow: 0px 4px 18px rgba(235, 126, 65, 0.4);
                        border-radius: 20px;
                        transform: rotate(-2deg);
                        @include text;
                        color: $white;
                        padding: 0 20px;
                        width: 100%;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 63px;
                        span {
                            padding-right: 10px;
                        }
                    }
                }
                .form-group {
                    margin-bottom: 25px;
                    input {
                        padding: 0 24px;
                        width: 100%;
                        background-color: $input-background;
                        color: $white;
                        @include text;
                        border-radius: 20px;
                        transform: rotate(-2deg);
                        height: 63px;
                        border: transparent;
                        &:focus {
                            outline: none;
                        }
                    }
                    ::placeholder {
                        color: $white;
                        @include text;
                    }
                }
            }
        }
    }
    .red-blure {
        background: #CE192E;
        opacity: 0.56;
        filter: blur(23px);
        width: 65px;
        height: 65px;
        position: absolute;
        top: 70px;
    }
    .read-blur-bottom {
        background: #E86B7A;
        opacity: 0.45;
        width: 197px;
        height: 180px;
        position: absolute;
        bottom: 0;
        right: 0;
        filter: blur(166.303px);
    }
}