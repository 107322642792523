@import '../../../styles/mixins/breakpoint.scss';
@import '../../../styles/mixins/color.scss';
@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/typography.scss';

.add-job-section-align {
    padding:86px 0 196px 0;
    @include breakpoint("max-md") {
        padding:0 0 120px 0;
    }
    @include breakpoint("max-sm") {
        padding:0 0 80px 0;
    }
    .relative-section{
        position: relative;
        .cup-image {
            background-image: url("../../../Assets/Images/cup.png");
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: 0;
            width: 48px;
            height: 46px;
            left: 18%;
            @include breakpoint("max-sm") {
                left: -6px;
            }
        }
        .full-round {
            background-image: url("../../../Assets/Images/full-round.png");
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: 60%;
            width: 40px;
            height: 40px;
            left: 20%;
        }
    }
    .add-job-image-alignment {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .add-job-img {
            position: relative;
            img {
                max-width: 449px;
                height: 336px;
                @include breakpoint("max-sm") {
                    max-width: 100%;
                    height: auto;
                }
            }
            .ball-image {
                background-image: url("../../../Assets/Images/ball.png");
                background-repeat: no-repeat;
                background-size: cover;
                width: 33px;
                height: 32px;
                position: absolute;
                right: -8%;
                top: 15%;
                @include breakpoint("max-sm") {
                    right: 0px;
                }
            }
            .dish-icon {
                background-image: url("../../../Assets/Images/dish-icon.png");
                background-repeat: no-repeat;
                background-size: cover;
                width: 37px;
                height: 33px;
                position: absolute;
                right: 0%;
                bottom: 0%;
            }
        }
    }
    .text-grid {
        h1 {
            @include heading-large;
            color: $blue;
            text-align: center;
            margin: 0 0 30px 0;
            @include breakpoint("max-sm") {
                font-size: 2rem !important;
                font-weight: 600;
                line-height: 1.3 !important;
            }
        }
        .child-text-center-align {
            display: flex;
            justify-content: center;

        }
        p {
            @include text;
            line-height: 27px;
            text-align: center;
            margin: 0;
            color: $gray;
            max-width: 675px;
            @include breakpoint("max-md") {
                max-width: 100%;
            }
        }
    }
}