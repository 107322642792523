@import '../../../styles/mixins/breakpoint.scss';
@import '../../../styles/mixins/color.scss';
@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/typography.scss';
@import '../../../styles/mixins/animation.scss';


.life-is-joke-section-align {
    padding: 140px 0;
    @include breakpoint("max-md") {
        padding: 120px 0;
    }
    @include breakpoint("max-sm") {
        padding: 80px 0;
    }
    position: relative;
    .light-blur-right-align{
        display: flex;
        justify-content: flex-end;
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(2 , 1fr);
        gap: 0 20px;
        align-items: center;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 40px 0;
        }
        .grid-items:nth-child(2) {
            position: relative;
            .first-image-style {
                background-image: url("../../../Assets/Images/win.png");
                background-repeat: no-repeat;
                background-size: cover;
                width: 70px;
                height: 70px;
                position: absolute;
                right: 10%;
                animation-name: rignt-side-icon;
                animation-duration: 2s ;
                animation-timing-function:linear;
                animation-iteration-count: infinite;
            }
            .vector-sec {
                background-image: url("../../../Assets/Images/vector1.png");
                background-repeat: no-repeat;
                background-size: cover;
                width: 45px;
                height: 41px;
                position: absolute;
                left: 20%;
                top: 10%;
                @include breakpoint("max-sm") {
                    left: 0;
                }
            }
            .vector-four {
                background-image: url("../../../Assets/Images/vector3.png");
                background-repeat: no-repeat;
                background-size: cover;
                width: 34px;
                height: 34px;
                position: absolute;
                left: 30%;
                bottom: 0%;
                @include breakpoint("max-sm") {
                    left: 15%;
                }
            }
            .vector-three {
                background-image: url("../../../Assets/Images/vector2.png");
                background-repeat: no-repeat;
                background-size: cover;
                width: 47px;
                height: 49px;
                position: absolute;
                left: 10%;
                top: 50%;
                // transform: translateY(-50%);.
                @include breakpoint("max-sm") {
                    left: 0;
                }
            }
        }
        .grid-items {
            .face-icon-center-align {
                display: flex;
                justify-content: center;
                img {
                    width: 421px;
                    height: 421px;
                    animation-name: scale-now-first;
                    animation-duration: 2s ;
                    animation-timing-function:linear;
                    animation-iteration-count: infinite;
                    @include breakpoint("max-sm") {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .joy-icon {
                margin-bottom: 10px;
                img {
                    animation-name: scale-now;
                    animation-duration: 2s ;
                    animation-timing-function:linear;
                    animation-iteration-count: infinite;
                }
            }
            .do-something {
                button {
                    @include blue-gradinent;
                    min-width: 291px;
                    padding: 0 24px;
                    color: $white;
                    height: 63px;
                    @include text;
                    box-shadow: 0px 4px 18px rgba(79, 65, 235, 0.4);
                    border-radius: 20px;
                    transform: rotate(-5deg);
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span {
                        padding-right: 13px;
                    }
                    @include breakpoint("max-sm") {
                        width: 100%;
                    }

                }
            }
            h1 {
                @include heading-large;
                font-weight: 600;
                margin: 0 0 2rem 0;
                color: $blue-2;
                @include breakpoint("max-sm") {
                    font-size: 2rem !important;
                    font-weight: 600;
                    line-height: 1.5 !important;
                }
            }
            .vector-line {
                margin-left: 301px;
                @include breakpoint("max-sm") {
                    display: flex;
                    justify-content: flex-end;
                    margin-left: 0;
                }
            }
            p {
                color: $gray;
                @include text;
                line-height: 26px;

            }
        }
    }
    .light-blur {
        width: 172px;
        height: 158px;
        background: #E86B7A;
        opacity: 0.75;
        filter: blur(133.505px);
        position: absolute;
        top: 0;
    }
}