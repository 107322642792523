

@keyframes down-up {
    0% {
      transform: translateY(0);
    }
    50%{
      transform: translateY(10px);
    }
    100%{
      transform: translateY(0);
    }
}
@keyframes down-mini {
    0% {
      transform: translateY(0);
    }
    50%{
      transform: translateY(-10px);
    }
    100%{
      transform: translateY(0);
    }
}
@keyframes scale-now {
    0% {
      transform: scale(1);
    }
    50%{
      transform:scale(1.4);
    }
    100%{
      transform: scale(1);
    }
}
@keyframes scale-now-first {
    0% {
      transform: scale(1);
    }
    50%{
      transform:scale(1.1);
    }
    100%{
      transform: scale(1);
    }
}
@keyframes rotate-icon {
    0% {
      transform: rotate(0deg);
    }
    50%{
      transform: rotate(15deg);
    }
    100%{
      transform: rotate(0deg);
    }
}

@keyframes rignt-side-icon {
  0% {
    transform: translateX(0);
    transform: translateY(0px);
  }
  50%{
    transform: translateX(-10px);
    transform: translateY(10px);
  }
  100%{
    transform: translateX(0);
    transform: translateY(0px);
  }
}