@import '../../../styles/mixins/breakpoint.scss';
@import '../../../styles/mixins/color.scss';
@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/typography.scss';


footer {
    background: #14122B;
    padding: 60px 0 0 0;
    .footer-logo {
        padding-bottom: 100px;
        @include breakpoint("max-sm") {
            padding-bottom: 60px;
        }
        h2 {
            @include heading-3;
            color: $orange;
            text-align: center;
            margin: 8px 0;
        }
        .footer-child-text-align {
            display: flex;
            justify-content: center;
            p {
                @include text;
                text-align: center;
                color: $gray-2;
                max-width: 261px;
                margin: 0;
            }
        }
    }
    .image-center-align {
        display: flex;
        justify-content: center;

    }
    .footer-background {
        @include breakpoint("max-sm") {
            display: none;
        }
        img {
            height: 297px;
            object-fit: cover;
            width: 100%;
        }
    }
}