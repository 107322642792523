
@import '../../../styles/mixins/breakpoint.scss';
@import '../../../styles/mixins/color.scss';
@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/typography.scss';

.move-list-section-align {
    padding: 156px 0;
    position: relative;
    background: #F3F2FF;
    @include breakpoint("max-md") {
        padding: 120px 0;
    }
    @include breakpoint("max-md") {
        padding: 80px 0;
    }
    .coutner-grid {
        display: grid;
        grid-template-columns: repeat(3 , 1fr);
        gap: 0 20px;
        padding: 0 6rem;
        @include breakpoint("max-md") {
            padding: 0;
        }
        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 20px 0;
        }
        .coutner-grid-items {
            h1 {
                font-size: 72px;
                text-align: center;
                color: #15122F;
                font-weight: 500;
                margin: 0 0 8px 0;
                @include breakpoint("max-md") {
                    @include heading-large;
                    font-weight: 500;
                }

            }
            p {
                @include heading-3;
                text-align: center;
                font-weight: 400;
                margin: 0;
                color: $gray;
                @include breakpoint("max-md") {
                    @include text;
                }
            }
        }
    }
    .toady-friend-box-center {
        display: flex;
        justify-content: center;
        padding-bottom: 60px;
        .toady-box {
            width: 811px;
            transform: rotate(2deg);
            box-shadow: 0px 0px 5px 2px rgba(151, 151, 151, 0.1);
            border-radius: 20px;
            @include breakpoint("max-md") {
                width: 100%;
            }
            .social-media {
                background-color: $white;
                padding: 20px 30px;
                border-radius: 0 0 24px 24px;
                @include breakpoint("max-sm") {
                    padding: 20px 15px;
                }
                .social-media-alignment {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        margin-right: 64px;
                        cursor: pointer;
                        @include breakpoint("max-sm") {
                            margin-right: 30px;
                        }
                    }
                    img:last-child {
                        margin-right: 0px;
                    }

                }
            }
            .tell-box {
                background: #E8E6FF;
                padding: 10px 30px;
                width: 100%;
                border-radius: 24px 24px 0 0;
                @include breakpoint("max-sm") {
                    padding: 10px 15px;
                }
                .text-icon-alignment{
                    display: flex;
                    align-items: center;
                    h3 {
                        @include heading-3;
                        color: #15122F;
                        margin: 0;
                        font-weight: 500;
                        padding-left: 2rem;
                        @include breakpoint("max-sm") {
                            @include text;
                        }
                    }
                }
            }
        }
    }
    .text-grid{
        h1 {
            @include heading-large;
            padding: 0 6rem;
            text-align: center;
            margin: 0 0 32px 0;
            color: $blue;
            @include breakpoint("max-md") {
                padding: 0;
            }
            @include breakpoint("max-sm") {
                font-size: 2rem !important;
                font-weight: 600;
                line-height: 1.3 !important;
            }
        }
        p {
            @include text;
            text-align: center;
            color: $gray;
            line-height: 25px;
            margin: 0 0 53px 0;
            @include breakpoint("max-md") {
                padding: 0 5rem;
            }
            @include breakpoint("max-sm") {
                padding: 0;
            }
        }
    }
    .light-blur {
        background: #E86B7A;
        opacity: 0.45;
        filter: blur(80.808px);
        width: 116px;
        height: 106px;
        position: absolute;
        left: 0;
        top: 0;        
    }
}