@import '../../styles/mixins/typography.scss';
@import '../../styles/mixins/breakpoint.scss';

.heading-title {
    p {
        @include heading-1();
        text-align: center;
        @include breakpoint("max-md") {
            @include heading-2();
        }
        @include breakpoint("max-sm") {
            @include heading-3;
        }
    }
}