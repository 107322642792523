@use '../mixins/breakpoint.scss';

@mixin heading-large {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 55px !important;
    line-height: 1.3;
  font-weight: 600;
  letter-spacing: -0.02em;
}

@mixin heading-1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -0.01em;
}

@mixin heading-2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.1;
}

@mixin heading-3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
}

@mixin heading-4 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin heading-5 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin heading-5-bold {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin heading-6 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin text-md {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin text {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin text-bold {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin text-sm {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

@mixin text-sm-bold {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;
}
