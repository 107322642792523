@import '../../../styles/mixins/breakpoint.scss';
@import '../../../styles/mixins/color.scss';
@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/typography.scss';
@import '../../../styles/mixins/animation.scss';


.hero-banner {
    background-color: $blue;
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    @include breakpoint("max-md") {
        padding: 80px 0;
        height: auto;
    }
    @include breakpoint("max-sm") {
        padding: 60px 0;
    }
    .blure-sec-blue-bottom {
        width: 600px;
        height: 500px;
        background: #1B155E;
        opacity: 0.56;
        filter: blur(271.123px);
        position: absolute;
        bottom: 0;
        @include breakpoint("max-sm") {
            width: 100%;
        }
    }
    .smile-icon-right-align {
        display: flex;
        justify-content: flex-end;
    }
    .smile-icon-right {
        background-image: url("../../../Assets/Images/smile.png");
        width: 84px;
        background-size: cover;
        height: 84px;
        background-repeat: no-repeat;
        position: absolute;
        animation-name: rotate-icon;
        animation-duration: 2s ;
        animation-timing-function:linear;
        animation-iteration-count: infinite;
        top: 86px;
    }
    .blur-first {
        background: #E86B7A;
        opacity: 0.75;
        filter: blur(181.707px);
        width: 208px;
        height: 181px;
        position: absolute;
        top: 0;
        right: 20%;
    }
    .round-blur {
        background: #CE5A19;
        opacity: 0.56;
        filter: blur(23px);
        width: 65px;
        margin-left: 154px;
        height: 65px;
        position: absolute;
        top: 70px;
    }
    .hero-grid {
        display: grid;
        grid-template-columns: 721px minmax(0 , 1fr);
        gap: 0 20px;
        justify-content: space-between;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 0px 0;
        }
        .hero-grid-items:nth-child(2) {
            position: relative;
            @include breakpoint("max-md") {
                display: flex;
                justify-content: center;
            }
            .extra-smaile {
                background-image: url("../../../Assets/Images/extra-smile.png");
                background-repeat: no-repeat;
                background-size: cover;
                width: 51px;
                height: 51px;
                position: absolute;
                animation-name: scale-now;
                animation-duration: 2s ;
                animation-timing-function:linear;
                animation-iteration-count: infinite;
                bottom: 0;
                left: 20%;
                @include breakpoint("max-md") {
                    left: 0;
                }
            }
        }
        .hero-grid-items:nth-child(1) {
            position: relative;
            .round-blur-first {
                position: absolute;
                bottom: 0;
                right: 20%;
                width: 65px;
                height: 65px;
                background: #CE192E;
                opacity: 0.56;
                filter: blur(23px);
            }
        }
        .hero-grid-items {
            .image-alignment {
                position: relative;
                display: flex;
                align-items: center;
                @include breakpoint("max-sm") {
                    display: block;
                }
                .first-image {
                    position: relative;
                    @include breakpoint("max-sm") {
                        display: flex;
                        justify-content: center;
                    }
                    img {
                       width: 148px;
                       animation-name: down-up;
                       animation-duration: 2s ;
                       animation-timing-function:linear;
                       animation-iteration-count: infinite;
                    }
                }
                .first-image::before {
                    background-image: url("../../../Assets/Images/dish.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 36px;
                    height: 30px;
                    content: "";
                    position: absolute;
                    top: 0;
                    animation-name: down-mini;
                    animation-duration: 2s ;
                    animation-timing-function:linear;
                    animation-iteration-count: infinite;
                    right: -15px;
                }
                .sec-image {
                    position: relative;
                    img {
                        width: 254px;
                        position: relative;
                        top: 75px;
                        animation-name: down-up;
                        animation-duration: 2s ;
                        animation-timing-function:linear;
                        animation-iteration-count: infinite;
                    }
                }
                .line-image::before {
                    background-image: url("../../../Assets/Images/line.png");
                    width: 31px;
                    height: 37px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: absolute;
                    right: 0;
                    top: 30%;
                    content: "";
                    @include breakpoint("max-md") {
                        right: -25%;
                    }
                    @include breakpoint("max-sm") {
                        right: 0px;
                    }
                }
                .half-round::before {
                    background-image: url("../../../Assets/Images/round.png");
                    width: 65px;
                    height: 60px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: absolute;
                    bottom: -10%;
                    content: "";
                    @include breakpoint("max-md") {
                        bottom: -20%;
                    }
                    @include breakpoint("max-sm") {
                        left: 0;
                    }
                }
                .bolt-round::before {
                    background-image: url("../../../Assets/Images/bolt.png");
                    width: 35px;
                    height: 35px;
                    right: -12%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: absolute;
                    bottom: -12%;
                    content: "";
                }
                .sec-image::before {
                    width: 130px;
                    height: 130px;
                    background: #CE5A19;
                    opacity: 0.56;
                    content: "";
                    position: absolute;
                    bottom: -20%;
                    left: 20%;
                    filter: blur(76.6615px);

                }
            }
            .bite-map-image {
                img{
                    width: 112px;
                    height: 112px;
                    animation-name: scale-now;
                    animation-duration: 2s ;
                    animation-timing-function:linear;
                    animation-iteration-count: infinite;

                }
            }
            .mask-image {
                margin-top: 60px;
                img {
                    animation-name: rotate-icon;
                    animation-duration: 2s ;
                    animation-timing-function:linear;
                    animation-iteration-count: infinite;
                }
            }
            .hero-button {
                button {
                    display: flex;
                    align-items: center;
                    @include button-gradinent;
                    min-width: 291px;
                    height: 63px;
                    transform: rotate(-5deg);
                    padding: 0 24px;
                    @include text;
                    justify-content: center;
                    font-weight: 500;
                    color: $white;
                    border: transparent;
                    border-radius: 20px;
                    span {
                        padding-right: 13px;
                    }
                    @include breakpoint("max-sm") {
                        width: 100%;
                    }
                }
            }
            .hero-text {
                @include breakpoint("max-sm") {
                    margin-top: 2rem;
                }
                h1 {
                @include heading-large;
                background: linear-gradient(
                278.36deg, #FF843F 27.38%, #FFFFFF 63.35%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 0 0 30px 0;
                @include breakpoint("max-md") {
                    margin: 0 0 60px 0;
                }
                @include breakpoint("max-sm") {
                    @include heading-3;
                    font-size: 45px !important;
                    line-height: 49px;
                }
                }
            }
        }
    }
}