
@import '../../../styles/mixins/breakpoint.scss';
@import '../../../styles/mixins/color.scss';
@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/typography.scss';


.job-making-section-align {
    padding-bottom: 195px;
    @include breakpoint("max-md") {
        padding-bottom: 120px;
    }
    .grid {
        display: grid;
        grid-template-columns: 516px minmax(0 , 1fr);
        gap: 0 60px;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 40px 0;
        }
        @include breakpoint("max-md") {
        .grid-items:nth-child(1) {
            order: 2;
        }
        .grid-items:nth-child(2) {
            order: 1;
        }
        }
        .grid-items {
            .poaster-image {
                @include breakpoint("max-md") {
                    display: flex;
                    justify-content: center;
                }
                img {
                    width: 516px;
                    @include breakpoint("max-sm") {
                        width: 100%;
                    }
                }
            }
            h1 {
                @include heading-large;
                color: $blue;
                font-weight: 600;
                margin: 0 0 48px 0;
                line-height: 70px;
                @include breakpoint("max-sm") {
                    font-size: 2rem !important;
                    font-weight: 600;
                    line-height: 1.3 !important;
                }
            }
            p {
                @include text;
                color: $gray;
                line-height: 25px;
                margin: 0 0 43px 0;
            } 
            .something-button {
                button {
                    display: flex;
                    align-items: center;
                    background: linear-gradient(271.4deg, #322993 -12.37%, #5D54C7 116.08%);
                    box-shadow: 0px 4px 18px rgba(79, 65, 235, 0.4);
                    min-width: 291px;
                    height: 63px;
                    transform: rotate(-5deg);
                    padding: 0 24px;
                    @include text;
                    justify-content: center;
                    font-weight: 500;
                    color: $white;
                    border: transparent;
                    border-radius: 20px;
                    span {
                        padding-right: 13px;
                    }
                    @include breakpoint("max-sm") {
                        width: 100%;
                    }
                }
            }
        }
    }
}